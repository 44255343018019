import React from 'react'
import { Container, Grid } from '@material-ui/core';

export default function Termsofuse() {
    return (
        <Container maxWidth="lg" component="main" style={{minHeight:'80vh'}}>
        <h1 style={{textAlign:'center'}}>TERMS OS USE</h1>
    </Container>
    )
}
